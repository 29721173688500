<template>
  <BidangForm mode="Tambah" module="Bidang"> </BidangForm>
</template>

<script>
import BidangForm from './form';

const BidangAdd = {
  name: 'BidangAdd',
  components: { BidangForm },
};

export default BidangAdd;
</script>
